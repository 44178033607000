import { IReconstructionTransaction } from "../pricing/pricing";
import { IHasDates } from "../shared/utils";


export enum GENERATE_TYPE {
    TEXT_TO_3D = 1,
    IMAGE_TO_3D = 2,
    EDIT_IMAGE = 3,
    CREATE_VIDEO = 4,
}

export enum TEXTURE_GENERATE_TYPE {
    EXISTING = 1,
    FOREIGN = 2,
}

export enum MEDIA_TYPE {
    MODEL = 1,
    IMAGE = 2,
    VIDEO = 3,
}

export enum IReconstructMethod {
    TRIPOSR = 'TRIPOSR',
    TRELLIS = 'TRELLIS'
}

export interface IReconstructTexture {
    modelURL: string
    images?: Array<string>;
    text?: string;
}

export interface IReconstructVideoOptions {
    images: Array<string>;
    text: string;
}

export interface IReconstruct {
    images?: Array<string>;
    method?: IReconstructMethod;
    remove_background?: boolean;
    text?: string;
    video_url?: string;
    // refine: boolean;
    action_id: RECONSTRUCTION_ACTION;
    source_job_id?: number;
    source_glb_url?: string;
    low_poly: boolean;
    seed: number;
    public: boolean;
    poly_count: number;
    quad_remesh: boolean;
    smoothness: boolean;
}

export interface IReconstructJobUI extends IReconstructJob {
    _isCurrent: boolean;
    _delayEnter?: number;
}

export interface IReconstructJob extends IHasDates {
    end_time: Date;
    error_log: any;
    glb_url: string;
    id: number;
    is_archived: boolean;
    method: string;
    params: string;
    ply_url: string;
    preview: string;
    start_time: Date;
    status: RECONSTRUCT_JOB_STATUS;
    steps: Array<any>;
    user_id: number;
    viewer_url: string;
    // source_id: number;
    // is_refined: number;
    text: string;
    action_id: RECONSTRUCTION_ACTION;
    credits_used: number;
    source_action_id: RECONSTRUCTION_ACTION;
    source_job_id: number;
    reconstruction_jobs_inputs: Array<IReconstructJobInput>;
    reconstruction_images: Array<IReconstructImage>;
    public: boolean;
}

export enum RECONSTRUCT_JOB_STATUS {
    Completed = 'Completed',
    Failed = 'Failed',
    'In Progress' = 'In Progress',
    Queued = 'Queued'
}

export interface IReconstructJobInput {
    id: number;
    text_input: string;
    job_id: number;
    image_url: string;
    video_url: string;
}

export interface IFilterOptions {
    limit: number;
    offset: number;
    is_desc: boolean;
    order_by: string;
}

export interface IReconstructJobOptions extends IHasDates, IFilterOptions {
    is_archived: boolean;
    action_id: Array<RECONSTRUCTION_ACTION>;
}

// export interface IReconstructJobImageOptions extends IHasDates, IFilterOptions {

// }

export interface IAllReconstructionJobs {
    allReconstructionJobs: IAllReconstructionJobsInner;
}

export interface IReconstructionJobQuery {
    reconstruction_jobs: IReconstructJob;
}

export interface IAllReconstructionJobsInner {
    count: number;
    rows: Array<IReconstructJob>;
}

// export interface IAllReconstructionImages {
//     allReconstructionImages: IAllReconstructionImagesQuery;
// }

// export interface IAllReconstructionImagesQuery {
//     count: number;
//     rows: Array<IReconstructImage>;
// }

export interface IReconstructJobsQueryData {
    allReconstructionJobs: IAllReconstructionJobs;
}

export interface IReconstructImage extends IHasDates {
    id: number;
    request_id: string;
    job_id: number;
    url: string;
    user_id: number;
    prompt: string;
}

export interface IReconstructImageSelect {
    image: IReconstructImage;
    job: IReconstructJobUI;
}

export interface IReconstructImageUI extends IReconstructImage {
    _delayEnter: number;
}

export interface ISimilarItem {
    name: string;
    product_id: number;
    image_url: string;
    score: number;
    credits: number;
    resource_default: string;
    resource_big: string;
    resource_id: number;
    poly_count: number;
    vert_count: number;
    // source_files: Array<ISourceFile>;
    products_resources_files_details: Array<ProductResourceFilesDetails>;
    products_resources_source_files: Array<ProductResourceSourceFile>;
}

export interface ProductResourceFilesDetails {
    file_name: string;
    file_size: number;
    id: number;
    resource_id: number;
}

export interface ProductResourceSourceFile {
    id: number;
    software_id: number;
}

export interface ISourceFile {
    id: number;
    software_id: number;
}

export interface IReconstructionAction {
    id: RECONSTRUCTION_ACTION;
    description: string;
    credits: number;
    estimated_duration: number;
    require_subscription: boolean;
}

export enum RECONSTRUCTION_ACTION {
    NOOP = -1,
    RECONSTRUCTION = 1,
    REFINE = 2,
    RE_TEXTURE = 3,
    PURCHASE_SIMILAR = 4,
    PURCHASE_OBJAVERSE = 5,
    RECONSTRUCTION_FROM_TEXT = 6,
    GENERATE_IMAGE = 7,
    REFINE_PBR = 8,
    UPSCALE_IMAGE = 9,
    GENERATE_VIDEO = 10,
    UPSCALE_VIDEO = 11,
    RE_TEXTURE_PBR = 12,
}

export enum SUBSCRIPTION_REASON {
    INSUFFICIENT_CREDITS = -2,
    PRIVATE = -1,
    REFINE = RECONSTRUCTION_ACTION.REFINE,
    REFINE_PBR = RECONSTRUCTION_ACTION.REFINE_PBR,
    UPSCALE_IMAGE = RECONSTRUCTION_ACTION.UPSCALE_IMAGE,
    GENERATE_VIDEO = RECONSTRUCTION_ACTION.GENERATE_VIDEO,
    GENERATE_IMAGE = RECONSTRUCTION_ACTION.GENERATE_IMAGE,
}

export const ACTIONS_3D = [RECONSTRUCTION_ACTION.RECONSTRUCTION, RECONSTRUCTION_ACTION.REFINE, RECONSTRUCTION_ACTION.REFINE_PBR, RECONSTRUCTION_ACTION.RE_TEXTURE, RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT, RECONSTRUCTION_ACTION.PURCHASE_SIMILAR, RECONSTRUCTION_ACTION.PURCHASE_OBJAVERSE, RECONSTRUCTION_ACTION.RE_TEXTURE_PBR] as Array<RECONSTRUCTION_ACTION>;
export const ACTIONS_2D = [RECONSTRUCTION_ACTION.GENERATE_IMAGE, RECONSTRUCTION_ACTION.UPSCALE_IMAGE] as Array<RECONSTRUCTION_ACTION>;
export const ACTIONS_VIDEO = [RECONSTRUCTION_ACTION.GENERATE_VIDEO, RECONSTRUCTION_ACTION.UPSCALE_VIDEO] as Array<RECONSTRUCTION_ACTION>;


export interface IAllReconstructionAction {
    data: IAllReconstructionActionData;
}

export interface IAllReconstructionActionData {
    allReconstructionActions: Array<IReconstructionAction>;
}

export interface IallReconstructionTransactions {
    data: IallReconstructionTransactionsData;
}

export interface IallReconstructionTransactionsData {
    count: number;
    rows: Array<IReconstructionTransaction>;
}

export interface IAiTuxtureDialogData {
    glbURL: string;
}

export enum CREATIONS_LIST_TYPE {
    INFO = 1,
    THUMBS = 2,
    HORIZON_THUMBS = 3,
    DESKTOP_INFO_HORIZON_THUMBS_MOBILE = 4,
}

export enum IMAGE_LIST_TYPE {
    VERTICAL = 1,
    HORIZONTAL = 2,
    HORIZONTAL_MOBILE = 3,
}

export interface IAdvancedOptions {
    pbr?: boolean;
    low_poly?: boolean;
    poly_count?: number;
    seed?: number;
    quad_remesh?: boolean;
    smoothness?: boolean;
    private?: boolean;
}

export interface IUpscaleImageOptions {
    images: Array<string>;
    action_id: RECONSTRUCTION_ACTION;
    prompt: string;
    public: boolean;
}

export interface IUpscaleUIImageOptions {
    src: string;
    private: boolean;
}

export interface IGenerateImagesFromText {
    text: string;
    public: boolean;
    samples: number;
}

export interface IEditImagesOptions {
    prompt: string;
    images: Array<string>;
    samples: number;
    public: boolean;
    // width: number;
    // height: number;
}

export interface ICreateVideoOptions {
    prompt?: string;
    images: Array<string>;
    method?: VIDEO_METHOD;
    resolution?: number;
    trajectory_type?: VIDEO_TRAJECTORY;
    public: boolean;
    // trajectory_type?: 'orbit' | 'spiral' | 'lemniscate' | 'zoom-in' | 'zoom-out' | 'dolly zoon-in' | 'dolly zoon-out' | 'move-forward' | 'move-backward' | 'move-up' | 'move-down' | 'move-left' | 'move-right';
}

export enum VIDEO_METHOD {
    SVC = 'SVC',
}

export enum VIDEO_TRAJECTORY {
    ORBIT = 'orbit',
    SPIRAL = 'spiral',
    LEMNISCATE = 'lemniscate',
    ZOOM_IN = 'zoom-in',
    ZOOM_OUT = 'zoom-out',
    DOLLY_ZOOM_IN = 'dolly zoon-in',
    DOLLY_ZOOM_OUT = 'dolly zoon-out',
    MOVE_FORWARD = 'move-forward',
    MOVE_BACKWARD = 'move-backward',
    MOVE_UP = 'move-up',
    MOVE_DOWN = 'move-down',
    MOVE_LEFT = 'move-left',
    MOVE_RIGHT = 'move-right',
}
