import { Injectable } from '@angular/core';
import { GlobalsService } from '../shared/globals.service';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import { UserQueryData } from '../auth/user';
import gql from 'graphql-tag';
import {
  IReconstructJobOptions,
  IAllReconstructionJobs,
  IReconstructionJobQuery,
  IAllReconstructionAction,
  IallReconstructionTransactions,
} from '../generate/generate';
import { SoftwareQueryData } from '../product/product';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  constructor(private apollo: Apollo, private globals: GlobalsService) { }

  private onRequestEnd() {
    this.globals.decreaseNumOfRequestsInProgress();
  }

  private onRequestStart() {
    this.globals.increaseNumOfRequestsInProgress();
  }

  public getValues(options: any): string {
    let isEmpty = (param: any) => {
      if (param === '') return true;
      if (param instanceof Array && !param.length) return true;
      return false;
    };
    let params = '';
    for (let i in options) {
      if (!isEmpty(options[i])) {
        let isString = typeof options[i] === 'string';
        let val = options[i];
        if (val === '[]') continue;
        if (isString) params += i + ':' + '"' + val + '"' + ',';
        else if (val instanceof Array) {
          if (val.length) {
            if (!(val[0] instanceof Array)) {
              let toAdd = i + ':[';
              if (typeof val[0] === 'string') {
                val.forEach((inner) => {
                  // if (typeof val === 'string')
                  //   toAdd += '"' + val + '",'
                  if (typeof inner === 'string') toAdd += '"' + inner + '",';
                  else toAdd += '' + val + ',';
                });
                toAdd = toAdd.substring(0, toAdd.length - 1);
              } else toAdd += val;
              params += toAdd + '],';
            } else params += i + ':[' + val.toString() + '],';
          }
        } else params += i + ':' + val + ',';
      }
    }
    if (options && Object.keys(options).length)
      params = params.substring(0, params.length - 1);
    return params;
  }

  public user(id: number): Observable<ApolloQueryResult<UserQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      users(id: ${id}) {
        id
        email
        avatar_url
        firstname
        lastname
        retailers_users {
          id
          retailer_id
          retailers {
            allow_downloads
            use_watermark
            visible_embedded
            use_hexa_naming
            show_description
            id
            logo_url
            name
            address
            website
            city
            zip
            country_code
            tax_id
            retailer_key
            retailers_budget {
              id
              retailer_id
              amount
            }
          }
        }
        users_roles {
          role_id
          roles {
            id
            name
          }
        }
        reconstruction_users_subscriptions {
          id
          user_id
          subscription_id
          product_id
          price_id
          status
          next_bill_date
          cancelled_at
          pending_downgrade_product_id
        }
        reconstruction_users_credits {
          id
          user_id
          credits
        }
      }
    }
    `;
    let res = this.apollo.query<UserQueryData>({
      query: query,
    }) as Observable<ApolloQueryResult<UserQueryData>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }

  public allReconstructionJobs(
    options: IReconstructJobOptions
  ): Observable<ApolloQueryResult<IAllReconstructionJobs>> {
    this.onRequestStart();
    // options = this.normalizeRetailers(options);
    const params = this.getValues(options);
    let query = gql`
    {
      allReconstructionJobs(${params}) {
        rows {
          id
          created_at
          updated_at
          end_time
          glb_url
          is_archived
          preview
          start_time
          status
          user_id
          viewer_url
          action_id
          source_action_id
          source_job_id
          public
          reconstruction_jobs_inputs {
            id
            text_input
            image_url
          }
          reconstruction_images {
            id
            url
            job_id
          }
        }
        count
      }
    }
    `;
    let res = this.apollo.query<IAllReconstructionJobs>({
      query: query,
    }) as Observable<ApolloQueryResult<IAllReconstructionJobs>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }

  public reconstructionJob(
    id: number
  ): Observable<ApolloQueryResult<IReconstructionJobQuery>> {
    this.onRequestStart();
    // options = this.normalizeRetailers(options);
    let query = gql`
    {
      reconstruction_jobs(id: ${id}) {
        id
        glb_url
        is_archived
        start_time
        user_id
        end_time
        status
        viewer_url
        preview
        created_at
        updated_at
        action_id
        source_action_id
        source_job_id
        public
        reconstruction_jobs_inputs {
          id
          text_input
          image_url
        }
        reconstruction_images {
          id
          url
          job_id
        }
      }
    }
    `;
    let res = this.apollo.query<IReconstructionJobQuery>({
      query: query,
    }) as Observable<ApolloQueryResult<IReconstructionJobQuery>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }

  // public allReconstructionImages(
  //   options: IReconstructJobImageOptions
  // ): Observable<ApolloQueryResult<IAllReconstructionImages>> {
  //   this.onRequestStart();
  //   const params = this.getValues(options);
  //   let query = gql`
  //   {
  //     allReconstructionImages (${params}) {
  //       rows {
  //         id
  //         url
  //         prompt
  //       }
  //       count
  //     }
  //   }
  //   `;
  //   let res = this.apollo.query<IAllReconstructionImages>({
  //     query: query,
  //   }) as Observable<ApolloQueryResult<IAllReconstructionImages>>;
  //   res.subscribe({ complete: this.onRequestEnd.bind(this) });
  //   return res;
  // }

  public allReconstructionActions(): Observable<
    ApolloQueryResult<IAllReconstructionAction>
  > {
    this.onRequestStart();
    // const params = this.getValues(options);
    let query = gql`
      {
        allReconstructionActions {
          id
          description
          credits
          estimated_duration
          require_subscription
        }
      }
    `;
    let res = this.apollo.query<IAllReconstructionAction>({
      query: query,
    }) as Observable<ApolloQueryResult<IAllReconstructionAction>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }

  public allReconstructionTransactions(): Observable<
    ApolloQueryResult<IallReconstructionTransactions>
  > {
    this.onRequestStart();
    // const params = this.getValues(options);
    let query = gql`
      {
        allReconstructionTransactions {
          rows {
            id
            created_at
            product_id
            amount
            credits
            action_id
          }
          count
        }
      }
    `;
    let res = this.apollo.query<IallReconstructionTransactions>({
      query: query,
    }) as Observable<ApolloQueryResult<IallReconstructionTransactions>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }

  public softwares(): Observable<ApolloQueryResult<SoftwareQueryData>> {
    this.onRequestStart();
    let query = gql`
    {
      software_enum {
        id
        enum_id
        software_name
        software_image_url
      }
    }`;
    let res = this.apollo.query<SoftwareQueryData>({ query: query }) as Observable<ApolloQueryResult<SoftwareQueryData>>;
    res.subscribe({ complete: this.onRequestEnd.bind(this) });
    return res;
  }
}
