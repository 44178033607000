import { Injectable } from '@angular/core';
import { IAssetOptions, KeyValuePair, RECONSTRUCTION_OPTIONS } from './enums';
import { StorageService } from 'ng-storage-service';
import {
  IReconstructionAction,
  RECONSTRUCTION_ACTION,
  VIDEO_TRAJECTORY,
} from '../generate/generate';
import { ITopupCardDetails } from '../pricing/pricing';

@Injectable({
  providedIn: 'root',
})
export class EnumsService {
  private _notificationTypes: Array<KeyValuePair>;
  constructor(private storage: StorageService) { }

  setNotificationTypes(data: Array<KeyValuePair>) {
    this._notificationTypes = data;
    this.storage.set('notificationTypes', data);
  }

  getNotificationTypes() {
    if (!this._notificationTypes)
      this._notificationTypes = this.storage.get('notificationTypes');
    return this._notificationTypes;
  }

  getTextOptions(): Array<KeyValuePair> {
    return [
      {
        key: RECONSTRUCTION_OPTIONS.TEXT_TO_IMAGE_TO_3D,
        value: 'generate preview images',
      },
      {
        key: RECONSTRUCTION_OPTIONS.TEXT_TO_3D,
        value: 'generate 3D from text',
      },
    ];
  }

  getImageActions(jobId: number, imageId: number): Array<IAssetOptions> {
    return [
      {
        key: `/free-online-ai-photo-editor/${jobId}/${imageId}`,
        value: 'edit image',
        action: RECONSTRUCTION_ACTION.GENERATE_IMAGE,
        icon: '/assets/images/magic-wang.svg'
      },
      {
        key: `/free-online-ai-3d-image-to-model-generator/${jobId}/${imageId}`,
        value: 'generate model',
        action: RECONSTRUCTION_ACTION.RECONSTRUCTION,
        icon: '/assets/images/picture.svg'
      },
      {
        key: `/free-online-image-upscale/${jobId}/${imageId}`,
        value: 'upscale image',
        action: RECONSTRUCTION_ACTION.UPSCALE_IMAGE,
        icon: '/assets/images/image-upscale.svg'
      },
      {
        key: `/online-ai-video-generator/${jobId}/${imageId}`,
        value: 'generate video',
        action: RECONSTRUCTION_ACTION.GENERATE_VIDEO,
        icon: '/assets/images/video-creation.svg'
      },
    ];
  }

  // getCookiesOptions(): Array<KeyValuePair> {
  //   return [
  //     {
  //       key: 'ad_storage',
  //       value: CookiesConsent.DENIED,
  //     },
  //     {
  //       key: 'ad_user_data',
  //       value: CookiesConsent.DENIED,
  //     },
  //     {
  //       key: 'ad_personalization',
  //       value: CookiesConsent.DENIED,
  //     },
  //     {
  //       key: 'analytics_storage',
  //       value: CookiesConsent.DENIED,
  //     },
  //   ];
  // }

  getActionByType(
    allActions: Array<IReconstructionAction>,
    type: RECONSTRUCTION_ACTION
  ): IReconstructionAction {
    return allActions.find((a) => a.id === type);
  }

  getActionsDictionary(): { [id: number]: string } {
    const res = {} as any;
    res[RECONSTRUCTION_ACTION.PURCHASE_SIMILAR] = 'purchase model';
    res[RECONSTRUCTION_ACTION.RECONSTRUCTION] = 'reconstruction';
    res[RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT] =
      'reconstruction from text';
    res[RECONSTRUCTION_ACTION.REFINE] = 'refine';
    res[RECONSTRUCTION_ACTION.RE_TEXTURE] = 're-texture';
    res[RECONSTRUCTION_ACTION.PURCHASE_OBJAVERSE] = 'purchase model';
    return res;
  }

  getJobsFilterOptions(): Array<KeyValuePair> {
    return [
      {
        key: RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT,
        value: 'text-based',
      },
      {
        key: RECONSTRUCTION_ACTION.RECONSTRUCTION,
        value: 'image-based',
      },
      {
        key: RECONSTRUCTION_ACTION.REFINE,
        value: 'refined',
      },
      {
        key: RECONSTRUCTION_ACTION.RE_TEXTURE,
        value: 'ai-texture',
      },
      {
        key: RECONSTRUCTION_ACTION.PURCHASE_OBJAVERSE,
        value: 'marketplace',
      },
      {
        key: RECONSTRUCTION_ACTION.PURCHASE_SIMILAR,
        value: 'premium',
      },
    ];
  }

  getAllTopupsOptions(): Array<ITopupCardDetails> {
    return [
      {
        price: 4,
        desc: '200 credits',
      },
      {
        price: 16,
        desc: '1000 credits',
      },
      {
        price: 48,
        desc: '4000 credits',
      },
    ];
  }

  getExamplePrompts(): Array<string> {
    return [
      'A photorealistic steampunk airship with intricate gears and brass accents, soaring through a cloudy sky with a distant mountain range below',
      'A playful cartoon robot cat made of mismatched appliances, perched on a windowsill filled with colorful flowers',
      'A majestic underwater temple built of coral and glowing bioluminescent plants, with schools of vibrant fish swimming around it',
      "A worn leather doctor's bag overflowing with antique medical tools made of polished silver and brass",
      'A minimalist white sculpture in the shape of a double helix, with a single red sphere floating at its center',
    ];
  }

  getTextureExamplePrompts(): Array<string> {
    return [
      'Realistic wood grain texture with a slight wear on the body and a glossy finish on the neck',
      'High-resolution scales with a metallic sheen for a chrome dragon or leathery texture with vibrant greens and blues for a more traditional look',
      'A photorealistic texture with a cute cat illustration on the side and a subtle coffee stain on the rim',
      'High-resolution brick texture with a layer of moss growing on the north-facing side and chipped paint on the window frames',
      'A combination of metallic textures for the main body and a rubberized grip on the manipulator for better control',
    ];
  }

  getAllVideoTrajectories(): Array<KeyValuePair> {
    const res = [] as Array<KeyValuePair>;
    for (let item in VIDEO_TRAJECTORY) {
      if (isNaN(Number(item))) {
        res.push({
          key: item,
          value: (VIDEO_TRAJECTORY as any)[item]
        })
      }
    }
    return res;
  }
}
