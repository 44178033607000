import { IReconstructJob, RECONSTRUCT_JOB_STATUS, RECONSTRUCTION_ACTION } from '../generate/generate';
import { PolyType, PolyShapeType } from '../retailer/retailer';
import { IHasDates } from './utils';

// export interface BroadcasterNotification {
//   text?: string;
//   htmlTemplate?: string;
//   action?: string;
//   type: BroadcasterNotificationType;
//   callback?: Function;
//   scope?: any;
//   duration?: number;
//   autoDismiss?: boolean;
// }

// export enum BroadcasterNotificationType {
//   Error,
//   Success,
//   Info,
//   Neutral,
//   Attention,
// }

export enum IP {
  'Client’s IP' = 1,
  'Hexa’s IP' = 2,
  'Background IP' = 3,
}

export interface KeyValuePair {
  key: any;
  value: any;
}

export interface IAssetOptions extends KeyValuePair {
  action: RECONSTRUCTION_ACTION;
  icon: string;
}

export interface KeyValueAnyPair {
  key: any;
  value: any;
}

export interface SystemEnum {
  enum_key: string;
  enum_name: string;
  enum_value: string;
  id: number;
  created_at: Date;
  updated_at: Date;
}

export enum CanvasPaintState {
  NOOP = 0,
  DRAW = 1,
  LINE = 2,
  WRITE = 3,
  VERTICAL_LINE = 4,
  HORIZONTAL_LINE = 5,
}

export interface Coordinates3D {
  x: number;
  y: number;
  z: number;
}

export interface KeyValuePoly {
  key: PolyType;
  value: PolyDesc;
}

export interface PolyDesc {
  name: string;
  shapeType: PolyShapeType;
  shapeName: string;
  icon: string;
  min?: number;
  max?: number;
}

export enum FormatsType {
  VIEWER_JSON = 0,
  OBJ = 1,
  FBX = 2,
  DAE = 4,
  BLEND = 5,
  glTF = 6,
  GLB = 7,
  USDZ = 8,
  PNG = 9,
  JPG = 10,
  MP4 = 11,
  TIFF = 12,
}

export enum Priority {
  LOWEST = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  HIGHEST = 5,
}

export enum ImageFormatsType {
  PNG = 9,
  JPG = 10,
  TIFF = 12,
}

export enum RenderStatus {
  PENDING = 'waiting for 3D approval',
  IN_PROGRESS = 'in progress',
  FEEDBACK = 'client feedback',
  APPROVED_OFFLINE = 'approved offline',
  APPROVED_ONLINE = 'approved online',
  DELIVERED = 'delivered',
}

export const DEFAULT_PREVIEW_DIM = 500;
export const DEFAULT_GIF_DIM = 200;
export const DEFAULT_3D_ICON = '//cdn.hexa3d.io/hotlink-ok/icons/3d-box.svg';
export const DEFAULT_VIDEO_ICON =
  '//cdn.hexa3d.io/hotlink-ok/icons/video-cam.svg';
export const DEFAULT_IMAGE_ICON = '//cdn.hexa3d.io/gallery.svg'; // change to something better

export enum StudioType {
  None = -1,
  Hexa = 1,
  Syndication = 2,
}

export enum FeedbackType {
  shape_doesnt_match = 1,
  colors_materials = 2,
  lighting_shadow = 4,
  camera_positioning = 5,
  others = 3,
}
export enum QaMode {
  BOTH = 1,
  FM_ONLY = 2,
  QS_ONLY = 3,
}

export enum PlaygroundNotificationType {
  ROLES_REFRESHED = 1,
  VERSION_UPDATE = 2,
  ANNOUNCEMENT = 6,
  THREE_D_RECONSTRUCTION_FINISHED = 4,
  JOB_STATUS_CHANGE = 5,
}

export interface IPlaygroundNotificationType extends IHasDates {
  description: string;
  id: PlaygroundNotificationType;
}

export interface IPlaygroundNotification extends IHasDates {
  notes: string;
  // icon: string;
  // title: string;
  read: boolean;
  reference_id: number;
  // notification_type: number;
  url?: string;
  delivery_tag: number;
  eventbus_address: string;
  id: number;
  job_id: number;
  notification_type: PlaygroundNotificationType;
  notifications_types: Array<IPlaygroundNotificationType>;
  reconstruction_jobs: Array<IReconstructJob>;
  status: RECONSTRUCT_JOB_STATUS;
  user_id: number;
  viewer_url: string;
  action_id?: RECONSTRUCTION_ACTION;
  thumbnail: string;
  thumbnail_fb: boolean;
  onClickArgs: Array<any>;
}

export interface IPlaygroundNotificationGroup {
  created_at: Date;
  items: Array<IPlaygroundNotification>;
}

export interface IPlaygroundNotificationListResponse {
  unread_count: number;
  count: number;
  items: Array<IPlaygroundNotification>
}

export interface IPlaygroundNotificationResponse {
  unread_count: number;
  count: number;
  items: Array<IPlaygroundNotification>;
}

export enum PlaygroundNotificationType {
  ON_RECONSTRUCTION_STATUS_CHANGE = 25,
  ON_RECONSTRUCTION_STATUS_CHANGE_2 = 125,
}

export interface IScreenNotification {
  text: string;
  action?: string;
  type: ScreenNotificationType;
  callback?: Function;
  scope?: any;
  duration?: number;
  autoDismiss?: boolean;
}

export enum ScreenNotificationType {
  Error,
  Success,
  Info,
  Neutral,
  Attention,
}

export interface ResumableSubject {
  state: ResumableState;
  object?: ResumableCallback;
}

export interface ResumableCallback {
  message?: any;
  progress?: number;
}

export enum ResumableState {
  FILE_PROGRESS = 1,
  COMPLETE = 2,
  ERROR = 3,
}

export class ResumableCDN {
  bucket: string;
  compress: boolean;
  uploaded_file_url: string;
  uploaded_file_name: string;
  constructor() {
    this.bucket = 'cdn.hexa3d.io';
  }
}

export interface PagingFilter {
  order_by: string;
  is_desc: boolean;
  limit?: number;
  offset?: number;
}

export enum SortDirection {
  Descending = 0,
  Ascending = 1,
}

export interface OrderFiled {
  [key: string]: string;
}

export enum IAuthType {
  SOCIAL,
  INITIAL,
  CONFIRMED,
}

export enum SignOnType {
  SIGN_IN = 'Sign-In',
  SIGN_UP = 'Sign-Up',
}

export enum RECONSTRUCTION_OPTIONS {
  TEXT_TO_3D = 1,
  TEXT_TO_IMAGE_TO_3D = 2,
  IMAGE_TO_3D = 3,
}

export enum CookiesConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export interface IFilesOptions {
  multiple: boolean;
  accept: string;
}

export enum HEXA_PLATFORM {
  CMS = 1,
  CREATORS = 2,
  GEN_AI = 3,
}