export const CORS_TOKEN = 'NgQun95Vma7QVVc34Lqxw7ETNWwmnuBA';
export const PREVIEW_FALLBACK = '/assets/images/cube.svg';
export const IMAGE_FALLBACK = '/assets/images/image.svg';
export const StrongPasswordRegx: RegExp =
  /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
export const PlatformName = 'HexaGen';
// export const themeColor = '#3f51b5';
export const themeColor = '#ff4081';
export const themeSeconderyColor = '#A33276';
export const imageCardDelay = 15;
export const noPaddDialog = 'no-padd-dialog';
export const transparentDialog = 'transparent-dialog';
export const defaultMonthlyCredits = 200;
export const mobileThreshold = 1024;
export const allowUnAuthPaths = [
  '/retailer/user/password',
  '/register',
  '/terms',
  '/privacy',
  '/convertor',
  '/login',
  '/signup',
  '/reset',
  '/update',
  '/home',
  '/',
  '/pricing',
  '/auth/sso',
  '/preview',
];
export const cardImageSuffix = '?w=200&h=200';
export const MAX_UPSCALE_DIM = 1024;
export const IMAGE_SAMPLES = 4;
export const PRIVATE_DESC = 'By toggling private mode on you can prevent this asset from being shown on the community. The model IP is yours.';
export const VIDEO_SEGMENT_PREFIX = 'hg-video-filter-';