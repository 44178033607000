import { ElementRef, Injectable } from '@angular/core';
import { BroadcasterService } from 'ng-broadcaster';
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { StorageService } from 'ng-storage-service';
import { IScreenNotification, ScreenNotificationType } from './enums';

@Injectable({
  providedIn: 'root'
})
export class BodyService {
  private lastTS: number;
  private onNotifyUser: Subscription;
  private expandMode: boolean;
  private lastScrollPos: number;
  private input: string;
  private _beforeUnload: boolean;
  public currentScrollPos: number;
  public bodyElement: ElementRef;
  public isShift: boolean;
  public isCtrl: boolean;
  public isBlur: boolean;
  public isTouch: boolean;
  public onScrollDirChange: Subject<boolean>;
  public onWindowSizeChange: Subject<number>;
  public requestsInProgress: Subject<boolean>;
  public onScrollChange: Subject<number>;
  public onExitIntent: Subject<null>;
  public onDarkThemeChange: Subject<boolean>;
  public isSearchRequestInProgress: boolean;
  private isOnline: boolean;
  private numOfRequestsInProgress: number;
  private htmlElement: ElementRef;
  private _launchParamsFiles: Array<File>;
  public hasFooter$: BehaviorSubject<boolean>;
  public hasMainNav$: BehaviorSubject<boolean>;
  // private isExitIntentOn: boolean;
  constructor(
      private broadcaster: BroadcasterService,
      private snackBar: MatSnackBar,
      private storage: StorageService
      // ,
      // private dialog: MatDialog
      // ,
      // private websocketService: WebsocketService
  ) {
      this.hasMainNav$ = new BehaviorSubject(true);
      this.isShift = false;
      this.isCtrl = false;
      this.isBlur = false;
      // this.lastScrollPos = window.scrollY;
      this.lastScrollPos = window.innerHeight;
      this.currentScrollPos = window.scrollY;
      this.onScrollDirChange = new Subject<boolean>();
      this.onWindowSizeChange = new Subject<number>();
      this.onScrollChange = new Subject<number>();
      this.onExitIntent = new Subject<null>();
      this.onDarkThemeChange = new Subject<boolean>();
      this.requestsInProgress = new Subject<boolean>();
      this.numOfRequestsInProgress = 0;
      // if (!this.isChrome())
      //     alert('Browser not supported!\nPlease use google chrome only!');
      this.bodyElement = new ElementRef(document.querySelector('body'));
      this.input = '';
      this.htmlElement = new ElementRef(document.querySelector('html'));


      this.lastTS = new Date().getTime();
      // fromEvent(window, 'scroll')
      //     .debounceTime(20)
      //     .throttleTime(20)
      //     .subscribe(this.onScroll.bind(this));
      var onScroll = () => {
          // window.removeEventListener('scroll', onScroll);
          // setTimeout(() => {
          //     (window.addEventListener as WhatWGAddEventListener)('scroll', onScroll, { passive: true });
          //     this.scrollDirHandler();
          // }, 500);
          const minTimeBetween = 2000;
          let maxPercentage = 0.6;
          let total = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);
          let current = window.innerHeight + (document.body.scrollTop + window.scrollY);
          this.scrollDirHandler();
          let position = current / total;
          if (position > maxPercentage) {
              const now = new Date().getTime();
              if (this.isAboveTS(now, this.lastTS, minTimeBetween)) {
                  this.broadcaster.broadcast('windowScroll80', position);
                  this.lastTS = now;
              }
              else {
                  setTimeout(() => {
                      onScroll()
                  }, now - this.lastTS);
              }
          }
          this.onScrollChange.next(window.scrollY);
      };
      (window.addEventListener as any)('scroll', onScroll, { passive: true });
      var onResize = () => this.onWindowSizeChange.next(window.innerWidth);
      (window.addEventListener as any)('resize', onResize, { passive: true });

      // window.addEventListener('scroll', this.onScroll.bind(this), false);

      fromEvent(this.bodyElement.nativeElement, 'keyup')
          .subscribe((e: any) => {
              switch (e.keyCode) {
                  case 27: {
                      this.onEsc();
                      break;
                  }
                  case 16: {
                      this.onShift(false);
                      break;
                  }
                  case 17: {
                      this.onCtrl(false);
                      break;
                  }
                  case 32: {
                      this.onSpace(false);
                      break;
                  }
              }
          });

      fromEvent(this.bodyElement.nativeElement, 'keydown')
          .subscribe((e: any) => {
              switch (e.keyCode) {
                  case 16: {
                      this.onShift(true);
                      break;
                  }
                  case 17: {
                      this.onCtrl(true);
                      break;
                  }
                  case 90: {
                      if (e.ctrlKey) {
                          if (e.shiftKey)
                              this.onCtrlY();
                          else
                              this.onCtrlZ();
                      }
                      break;
                  }
                  case 89: {
                      if (e.ctrlKey) {
                          this.onCtrlY()
                      }
                      break;
                  }
                  case 32: {
                      this.onSpace(true);
                      break;
                  }
              }
          });

      fromEvent(this.bodyElement.nativeElement, 'click').subscribe(
          (evt: any) => this.broadcaster.broadcast('onclick', evt.target)
      );

      fromEvent(window, 'popstate')
          .subscribe((e: any) => {
              this.broadcaster.broadcast('popstate', e);
          });

      fromEvent(window, 'blur')
          .subscribe((e: any) => {
              this.onShift(false);
              this.onCtrl(false);
          });

      // window.addEventListener('scroll', () => {
      //     this.onScroll.apply(this);
      // }, false);

      this.onNotifyUser = this.broadcaster.on('notifyUser').subscribe(
          (data: any|IScreenNotification) => {
              if (!data.text) return;
              let c = {} as MatSnackBarConfig;
              if (data.autoDismiss || typeof data.autoDismiss === 'undefined')
                  c.duration = typeof data.duration === 'number' ? data.duration : data.type == ScreenNotificationType.Error ? 10000 : 5000;
              c.panelClass = ScreenNotificationType[data.type];
              if (c.panelClass)
                c.panelClass = c.panelClass.toLowerCase();
              data.action = data.action || 'Got it';
              let ref = this.snackBar.open(data.text, data.action, c);
              if (typeof data.callback === 'function') {
                  ref.onAction().subscribe(() => {
                      if (data.scope)
                          data.callback.apply(data.scope);
                      else
                          data.callback();
                  });
              }
          }
      );

      const isTouch = this.storage.get('isTouchDevice');
      if (isTouch)
          this.setTouch();
      else
          window.addEventListener('touchstart', this.onTouchStart.bind(this), false);

      fromEvent(window, 'online').subscribe(
          this.updateOnlineStatus.bind(this)
      )
      fromEvent(window, 'offline').subscribe(
          this.updateOnlineStatus.bind(this)
      )
      this.updateOnlineStatus();
      this.exitIntent();

      this.addLink('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap', 'stylesheet');
      this.addLink('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,700,700i&display=swap', 'stylesheet');

      if ('launchQueue' in window) {
          const windowAny = window as any;
          windowAny.launchQueue.setConsumer(async (launchParams: any) => {
              if (!launchParams.files.length)
                  return;
              let files = [] as Array<any>;
              launchParams.files.forEach((element: any) => {
                  files.push(element.getFile());
              });
              this._launchParamsFiles = await Promise.all(files);
              // this._launchParamsFiles = launchParams.files;
              // this._launchParamsFiles = files;
              this.broadcaster.broadcast('launchQueueFiles', launchParams.files);
          });
      }
      this.hasFooter$ = new BehaviorSubject<boolean>(true);
  }

  get launchParamsFiles() {
      return this._launchParamsFiles;
  }

  resetLaunchParamsFiles() {
      delete this._launchParamsFiles;
  }


  get beforeUnload() {
      return this._beforeUnload;
  }

  set beforeUnload(state: boolean) {
      if (this._beforeUnload !== state) {
          this._beforeUnload = state;
          if (this._beforeUnload)
              window.addEventListener('beforeunload', this.onBeforeUnload);
          else
              window.removeEventListener('beforeunload', this.onBeforeUnload);
      }
  }

  public setWindowInfiniteScroll(state: boolean) {
      if (state)
          this.htmlElement.nativeElement.classList.add('infinite-scroll');
      else
          this.htmlElement.nativeElement.classList.remove('infinite-scroll');
  }

  private onBeforeUnload(evt: Event) {
      evt.preventDefault();
      return ((evt as any).returnValue = '');
  }

  private updateOnlineStatus() {
      this.isOnline = navigator.onLine;
      if (this.isOnline) {
          this.broadcaster.broadcast('online');
          this.bodyElement.nativeElement.classList.remove('offline');
      }
      else {
          this.broadcaster.broadcast('offline');
          this.bodyElement.nativeElement.classList.add('offline');
      }
  }

  private setTouch() {
      this.isTouch = true;
      this.storage.set('isTouchDevice', 'true');
      this.bodyElement.nativeElement.classList.add('touch-device');
  }

  private onTouchStart() {
      this.setTouch();
      window.removeEventListener('touchstart', this.onTouchStart);
  }

  // private toggleWakandaEasterEgg() {
  //     let style = document.getElementById('wakandaEasterEgg');
  //     if (style) { // remove
  //         style.parentElement.removeChild(style);
  //     }
  //     else { // add
  //         let s = '@font-face {font-family: "WAKANDA";font-style: normal;font-weight: normal;src: url(https://cdn.creators3d.com/hotlink-ok/site/ModernWakandan-Bold-.ttf) format("truetype");}';
  //         s += 'html body, html body .title, html body .mdc-card, html body .mat-select, html body .mat-form-field, html body .mdc-button, html body .mdc-button--raised, html body .mdc-icon-button, html body .mat-stroked-button, html body .mat-flat-button, html body mdc-fab, html body .mat-mdc-mini-fab {font-family: "WAKANDA","Roboto", sans-serif;}';
  //         s += 'html body {background-image: url(https://ih1.redbubble.net/image.447771934.1752/flat,800x800,075,f.u2.jpg);background-size: contain;background-repeat: no-repeat;}';
  //         style = document.createElement('style');
  //         style.appendChild(document.createTextNode(s));
  //         style.setAttribute('id', 'wakandaEasterEgg');
  //         document.body.appendChild(style);
  //     }
  // }

  private scrollDirHandler() {
      let lastState = this.lastScrollPos > this.currentScrollPos;
      this.lastScrollPos = this.currentScrollPos;
      this.currentScrollPos = window.scrollY;
      let currentState = this.lastScrollPos > this.currentScrollPos;
      if (lastState != currentState) {
          this.onScrollDirChange.next(currentState);
      }
  }

  private onEsc() {
      this.broadcaster.broadcast('esc');
  }

  private onShift(state: boolean) {
      this.isShift = state;
      this.broadcaster.broadcast('shift', this.isShift);
  }

  private onCtrl(state: boolean) {
      this.isCtrl = state;
      this.broadcaster.broadcast('ctrl', this.isCtrl);
  }

  private onSpace(isDown: boolean) {
      this.broadcaster.broadcast('space', isDown);
  }

  private onCtrlZ() {
      this.broadcaster.broadcast('ctrlZ');
  }

  private onCtrlY() {
      this.broadcaster.broadcast('ctrlY');
  }

  // private onScroll(evt) {
  //     let maxPercentage = 0.8;
  //     let total = document.body.scrollHeight;
  //     let current = window.innerHeight + (document.body.scrollTop + window.scrollY);
  //     let position = current / total;
  //     if (position > maxPercentage) {
  //         if (this.isAboveTS(new Date().getTime(), this.lastTS, 2000)) {
  //             this.broadcaster.broadcast('windowScroll80', position);
  //             this.lastTS = new Date().getTime();
  //         }
  //     }
  // };

  private isAboveTS(present: number, past: number, maxMS: number) {
      return (present - past > maxMS);
  };

  public addLink(href: string, rel: string) {
      let link = document.createElement('link');
      link.rel = rel;
      link.href = href;
      document.body.appendChild(link);
  }

  public scrollTo(currentPos: number = window.scrollY, finalPos: number, increment = document.body.scrollHeight / 50, counter?: number, asec?: boolean) {
      try {
          window.scroll({
              top: finalPos,
              // left: 0,
              behavior: 'smooth'
          });
          return;
      } catch (e) { }
      counter = counter ? (counter + 1) : 1;
      asec = typeof asec === 'boolean' ? asec : currentPos > finalPos;
      if (asec)
          currentPos -= increment;
      else
          currentPos += increment;
      window.scrollTo(0, currentPos);
      if ((asec ? currentPos > finalPos : currentPos < finalPos) && counter < 50)
          setTimeout(() => {
              this.scrollTo(currentPos, finalPos, increment, counter, asec);
          }, 10);
  }

  public scrollTop() {
      this.scrollTo(window.scrollY, document.body.scrollHeight, document.body.scrollHeight / 50);
  }

  public scrollBottom() {
      this.scrollTo(window.scrollY, document.body.scrollHeight, (document.body.scrollHeight) / 50);
  }

  public scrollToElement(element: ElementRef, block = 'start') {
      if (element.nativeElement.scrollIntoView) {
          element.nativeElement.scrollIntoView({ behavior: 'smooth', block: block });
      }
      else {
          this.scrollTo(window.scrollY, element.nativeElement.offsetTop, document.body.scrollHeight / 50);
      }
  }

  public toggleExpand() {
      this.expandMode = !this.expandMode;
      this.bodyElement.nativeElement.querySelector('.content-wrap').classList[this.expandMode ? 'add' : 'remove']('expand');
      this.storage.set('expandMode', this.expandMode);
  }

  public getExpandMode() {
      return this.expandMode;
  }

  public setLastExpandMode() {
      let expandMode = this.storage.get('expandMode');
      if (expandMode) {
          this.expandMode = !!!expandMode;
          this.toggleExpand();
      }
  }

  public increaseNumOfRequestsInProgress() {
    setTimeout(() => {
      this.numOfRequestsInProgress++;
      this.broadcastRequestsInProgress();
    });
  }

  public decreaseNumOfRequestsInProgress() {
    setTimeout(() => {
      this.numOfRequestsInProgress--;
      // if (this.numOfRequestsInProgress < 0)
      //     this.numOfRequestsInProgress = 0;
      this.broadcastRequestsInProgress();
    });
  }

  public resetNumOfRequestsInProgress() {
    setTimeout(() => {
      this.numOfRequestsInProgress = 0;
      this.broadcastRequestsInProgress();
    });
  }

  get isProd(): boolean {
      return window.location.host == 'www.creators3d.com';
  }

  private broadcastRequestsInProgress() {
      setTimeout(() => {
          // console.log('numOfRequestsInProgress: ' + this.numOfRequestsInProgress);
          this.requestsInProgress.next(this.numOfRequestsInProgress > 0);
      });
  }

  private exitIntent() {
      var onWindow = function (event: any, callback: any) {
          // if (typeof window.addEventListener !== "undefined") {
          window.addEventListener(event, callback, false);
          // }
          // else {
          //     window.attachEvent("on" + event, function() {
          //         callback.call(window);
          //     });
          // }
      };

      var onWindowLeave = (callback: Function) => {
          onWindow("mouseout", (originalEvent: any) => {
              var event = originalEvent ? originalEvent : window.event,
                  element = event.relatedTarget || event.toElement;

              if (!element || element.nodeName === "HTML") {
                  if (typeof callback === "function") {
                      callback();
                  }
              }
          });
      };

      onWindowLeave(() => {
          this.onExitIntent.next(null);
      });
  }
}
